import Page from 'components/Page';
import logo200Image from 'assets/img/logo/logo_200.png';
import React from 'react';
import { Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { MdExitToApp } from 'react-icons/md';
import * as myUrl from '../urlLink';
// import * as firebase from 'firebase/app';
// import { getThemeColors } from 'utils/colors';
import { Redirect } from 'react-router-dom';

// const colors = getThemeColors();

// const perf = firebase.performance();

class CheckGudangTES extends React.Component {
  getListbyPaging() {
    const urlA = myUrl.url_allGudang;

    const option = {
      method: 'GET',
      json: true,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: window.localStorage.getItem('tokenOrder'),
      },
    };

    fetch(urlA, option)
      .then(response => {
        // trace.stop();
        if (response.ok) {
          return response.json();
        } else {
          // this.showNotification("Koneksi ke server gagal!", 'error');
        }
      })
      .then(data => {
        console.log(data);
        this.setState({ result: data.data });
      });
  }

  componentDidMount() {
    this.getListbyPaging(this.state.currentPage, this.state.todosPerPage);
  }

  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    redirect: false,
    pilihGudang: '',
    namaGudang: '',
  };

  signOut = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('orderList');
    this.setState({
      redirect: true,
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
  };

  Masuk = () => {
    window.localStorage.setItem('gID', this.state.pilihGudang);
    window.localStorage.setItem('gName', this.state.namaGudang);
    if (this.state.pilihGudang !== '') {
      this.props.history.push('/dashboard');
    } else {
      this.props.history.push('/');
    }
    console.log(this.state.pilihGudang);
    console.log(this.state.namaGudang);
  };

  setGroup = event => {
    var nama = this.state.result.find(function (element) {
      return element.Out_Code === event.target.value;
    });
    this.setState({
      pilihGudang: event.target.value,
      namaGudang: nama.Out_Name,
    });
    console.log('HASIL OUTCOE', event.target.value);
  };

  render() {
    var listGudang = this.state.result;
    const renderGudang =
      listGudang &&
      listGudang.map((todo, i) => {
        return <option value={todo.Out_Code}>{todo.Out_Name}</option>;
      });

    //totaldata

    return (
      <Page
        // title="Pesanan Yang Harus Selesai Malam Ini"
        // breadcrumbs={[{ name: 'Order', active: true }]}
        className="CheckGudang"
      >
        {this.renderRedirect()}
        <Row
          style={{
            height: '80vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Col sm={4} md={4} lg={4}>
            <Card>
              <div className="text-center pb-4">
                <img
                  src={logo200Image}
                  className="rounded"
                  style={{ width: 60, height: 60, marginTop: '5%' }}
                  alt="logo"
                />
              </div>
              <CardBody>
                <Label>Silahkan Pilih Gudang:</Label>
                <Input
                  type="select"
                  autoComplete="off"
                  name="select"
                  color="primary"
                  style={{ marginRight: '1px' }}
                  onChange={this.setGroup}
                >
                  <option value={0} disabled selected hidden id="pilih">
                    Pilih Gudang
                  </option>
                  {renderGudang}
                </Input>
                <div
                  className="text-center pt-1"
                  style={{ textAlign: 'center' }}
                >
                  <Col style={{ textAlign: 'center' }}>
                    <br></br>
                    <Button style={{ width: '150px' }} onClick={this.Masuk}>
                      Masuk
                    </Button>
                    <br></br>
                    <Label>Atau</Label>
                    <br></br>
                    <Button
                      outline
                      onClick={this.signOut}
                      style={{ width: '150px' }}
                    >
                      <MdExitToApp size={25} /> Keluar
                    </Button>
                  </Col>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}
export default CheckGudangTES;
