// //URL LOGIN
// var url_login = "https://staging-api.cfu.pharmalink.id/auth/login";
// var url_changeForgottenPassword = "https://staging-api.cfu.pharmalink.id/auth/forgotpassword";
// var url_verifyOTP = "https://staging-api.cfu.pharmalink.id/auth/verifyotp";
// var url_changePassword = "https://staging-api.cfu.pharmalink.id/auth/changepassword";
// var url_loginChangePassword = "https://staging-api.cfu.pharmalink.id/auth/changepassword";

const dotenv = require('dotenv');
dotenv.config();

if (window.location.hostname.includes('localhost')) {
  //base url
  var url_allGudang =
    'https://api.chc.pharmalink.id/monitoring-gudang-chc/GetAllGudang?type=allAuth';

  // PILIH TYPE ECOMMERCE
  var url_pilihEcommerce =
    'https://api.chc.pharmalink.id/monitoring-gudang-chc/GetAllOutlet?';

  // URL CEK RESI
  var url_cekResi =
    'https://api.cfu.pharmalink.id/shopee-api-client/v2/downloadshippingdoc';
  var url_getPickupTime =
    'https://api.pharmanet.id/DEVELOPMENT/Shopee_CHC/GetTimeSlot.php?OrderID=';
  var url_requestPickup =
    'https://api.cfu.pharmalink.id/shopee-api-client/v2/requestpickup';

  //URL LOGIN
  var url_login = 'https://api.cfu.pharmalink.id/auth/login';
  var url_changeForgottenPassword =
    'https://api.cfu.pharmalink.id/auth/forgotpassword';
  var url_verifyOTP = 'https://api.cfu.pharmalink.id/auth/verifyotp';
  var url_changePassword = 'https://api.cfu.pharmalink.id/auth/changepassword';
  var url_loginChangePassword =
    'https://api.cfu.pharmalink.id/auth/changepassword';
} else if (window.location.hostname.includes('staging')) {
  //base url
  url_allGudang =
    'https://api.chc.pharmalink.id/monitoring-gudang-chc/GetAllGudang?type=allAuth';

  // PILIH TYPE ECOMMERCE
  url_pilihEcommerce =
    'https://api.chc.pharmalink.id/monitoring-gudang-chc/GetAllOutlet?';

  // URL CEK RESI
  url_cekResi =
    'https://api.cfu.pharmalink.id/shopee-api-client/v2/downloadshippingdoc';
  url_getPickupTime =
    'https://api.pharmanet.id/PRODUCTION/Shopee_CHC/GetTimeSlot.php?OrderID=';
  url_requestPickup =
    'https://api.cfu.pharmalink.id/shopee-api-client/v2/requestpickup';

  //URL LOGIN
  url_login = 'https://api.cfu.pharmalink.id/auth/login';
  url_changeForgottenPassword =
    'https://api.cfu.pharmalink.id/auth/forgotpassword';
  url_verifyOTP = 'https://api.cfu.pharmalink.id/auth/verifyotp';
  url_changePassword = 'https://api.cfu.pharmalink.id/auth/changepassword';
  url_loginChangePassword = 'https://api.cfu.pharmalink.id/auth/changepassword';
} else {
  //base url
  url_allGudang =
    'https://api.chc.pharmalink.id/monitoring-gudang-chc/GetAllGudang?type=allAuth';

  // PILIH TYPE ECOMMERCE
  url_pilihEcommerce =
    'https://api.chc.pharmalink.id/monitoring-gudang-chc/GetAllOutlet?';

  // URL CEK RESI
  url_cekResi =
    'https://api.cfu.pharmalink.id/shopee-api-client/v2/downloadshippingdoc';
  url_getPickupTime =
    'https://api.pharmanet.id/PRODUCTION/Shopee_CHC/GetTimeSlot.php?OrderID=';
  url_requestPickup =
    'https://api.cfu.pharmalink.id/shopee-api-client/v2/requestpickup';

  //URL LOGIN
  url_login = 'https://api.cfu.pharmalink.id/auth/login';
  url_changeForgottenPassword =
    'https://api.cfu.pharmalink.id/auth/forgotpassword';
  url_verifyOTP = 'https://api.cfu.pharmalink.id/auth/verifyotp';
  url_changePassword = 'https://api.cfu.pharmalink.id/auth/changepassword';
  url_loginChangePassword = 'https://api.cfu.pharmalink.id/auth/changepassword';
}

export {
  url_login,
  url_allGudang,
  url_changeForgottenPassword,
  url_verifyOTP,
  url_changePassword,
  url_loginChangePassword,
  url_pilihEcommerce,
  url_cekResi,
  url_getPickupTime,
  url_requestPickup,
};
