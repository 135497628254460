import logo200Image from 'assets/img/logo/logo.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-0.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from 'utils/bemnames';

var orderList = { "2": [2] };

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navItems2 = [
  { to: '/dashboard', id: '2', name: 'Dashboard', exact: false },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {

  constructor(props) {
    super(props)

    if (window.localStorage.getItem('orderList')) {
      orderList = JSON.parse(window.localStorage.getItem('orderList'));
    } else {
    }
  }

  state = {
    isOpenPelapak: false,
    isOpenMasterB2B: false,
    isOpenExternal: true
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];
      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  //added by Master I team at 11/10/2019
  refreshSamePage = (currPath, toPath) => () => {
    var temporary = "http://localhost:3000" + toPath;
    console.log(currPath + " " + temporary);
    if (currPath === temporary) {
      window.location.reload(false);
    }
  }

  allFound = (master) => {
    return master.some(menu => Object.keys(orderList).includes(menu.id));
  }

  handleSidebarControlButton = event => {
    // event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {

    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo200Image}
                width="40"
                height="30"
                className="pr-2"
                alt=""
              />
              <span className="text-white">
                Request Pickup
              </span>
            </SourceLink>
          </Navbar>

          <Nav vertical>
            {/* EXTERNAL */}
            {navItems2.map(({ to, name, exact }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                  onClick={this.handleSidebarControlButton}
                >
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;