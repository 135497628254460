import React from 'react';

import { Nav, Navbar, NavItem } from 'reactstrap';

//import SourceLink from 'components/SourceLink';

const Footer = () => {
  return (
    <Navbar>
      <Nav navbar>
        <NavItem>
          2020 Request Pickup 1.0.0
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
