import { STATE_FORGETPASS, STATE_LOGIN } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PrivateRoute from 'components/Layout/PrivateRoute';
import PageSpinner from 'components/PageSpinner';
import CheckGudang from 'pages/Order/CheckGudang';
import AuthPage from 'pages/template/AuthPage';
import ResetPasswordForm from 'components/ResetPasswordForm';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import * as firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDPJxUKdofUnKhDCFOgZnQFjTqMWqqw56g",
  authDomain: "neo-genesis-ordermonitoring.firebaseapp.com",
  databaseURL: "https://neo-genesis-ordermonitoring.firebaseio.com",
  projectId: "neo-genesis-ordermonitoring",
  storageBucket: "neo-genesis-ordermonitoring.appspot.com",
  messagingSenderId: "937266444089",
  appId: "1:937266444089:web:b54f43bda668efd1199ae8"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const CetakResi = React.lazy(() => import('pages/Order/CetakResi'));


const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  state = {
    title: "",
    color: ""
  }

  setTitle = (title, color) => {
    this.setState({ title: title, color: color })
  }

  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />
            <LayoutRoute
              exact
              path="/lupapassword"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_FORGETPASS} />
              )}
            />
            <PrivateRoute
              exact
              menuID="1"
              path="/resetpassword"
              layout={EmptyLayout}
              component={props => (
                //<ResetPWd>
                <ResetPasswordForm {...props} />
              )}
            />
             <PrivateRoute
              exact
              menuID="2"
              path="/"
              layout={EmptyLayout}
              component={props => (
                <CheckGudang {...props} />
              )}
            />
            

            <MainLayout breakpoint={this.props.breakpoint} title={this.state.title} color={this.state.color}>
              <React.Suspense fallback={<PageSpinner />}>
                <PrivateRoute exact menuID="2" path="/dashboard" setTitle={this.setTitle} component={CetakResi} />
              </React.Suspense>
            </MainLayout>
            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);